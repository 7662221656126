@import 'styles/variables';

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: spacing(4);
  margin-top: spacing(8);

  .translationMemories {
    grid-column: 1/3;
  }

  .catJobs {
    margin-top: spacing(6);
  }
}
