@import 'styles/variables';

.titleRow {
  display: flex;
  align-items: center;
  margin-bottom: spacing(4);

  .toolTip {
    margin-left: spacing(4);
  }
}