@import 'styles/variables';

.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: spacing(17);

  .label {
    margin-bottom: spacing(1);
    color: $color-input-main;
    font-size: $font-size-14;
  }

  .valueText {
    text-align: left;
  }

  .boldValueText {
    text-align: left;
    font-weight: $font-weight-bold;
  }
}
