@import 'styles/variables';

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &::after {
    content: ' ';
    display: block;
    width: 16px;
    height: 16px;
    margin: 4px;
    border-radius: 50%;
    border: 4px solid $color-black;
    border-color: $color-black transparent $color-black transparent;
    animation: spin 1.2s linear infinite;
  }
}