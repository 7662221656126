@import 'styles/variables';
@import 'styles/mixins';

//HeaderItem
.headingWrapper {
  display: grid;
  position: relative;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  grid-gap: spacing(2);

  input {
    order: -1;
  }
}

.iconButton {
  padding: spacing(1);

  svg {
    padding: 0;
    width: 20px;
    height: 20px;
  }

  svg g path,
  svg path {
    fill: $color-input-main;
    fill-opacity: 1;
  }
}
