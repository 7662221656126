@import 'styles/variables';

.header {
  font-size: $font-size-14;
  color: $color-text-dark;
  font-weight: $font-weight-bold;
}
.padding {
  padding-left: spacing(4);
}
.dataTable {
  box-shadow: none;
  border: none;

  td {
    vertical-align: text-top;
  }
  tbody {
    tr {
      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }
}

.objectContainer {
  display: flex;
}
