@import 'styles/variables';
@import 'styles/mixins';

.new {
  @include statusTagStyle($color-warning, $color-warning-dark);
}
.registered {
  @include statusTagStyle($color-highlight);
}
.submitted_to_client,
.tasks_submitted_to_vendors,
.completed,
.tasks_completed {
  @include statusTagStyle($color-success);
}
.cancelled {
  @include statusTagStyle($color-border, $color-text);
}
.accepted {
  @include statusTagStyle($color-dark);
}
.rejected {
  @include statusTagStyle($color-error);
}
.tasks_in_progress,
.corrected {
  @include statusTagStyle($color-primary);
}