@import 'styles/variables';

.dataTable {
  margin-top: spacing(4);
  padding-top: spacing(1);
}

.firstHeader {
  padding-left: spacing(8);
  font-size: $font-size-14;
}

.header {
  font-size: $font-size-14;
}

.row {
  display: grid;
  grid-auto-flow: column;
  width: max-content;
  gap: 10px;
  align-items: center;
}

.shrinkIcon {
  height: 16px;
  width: 20px;
  svg {
    margin: spacing(0.5);
  }
}

.expandedIcon svg path {
  fill: $color-expanded-icon;
}

.tableClassName {
  th {
    border-bottom: 1px solid $color-border;
  }
  tbody {
    tr {
      &:last-child {
        td {
          border-bottom: 1px solid $color-border;
        }
      }
    }
  }
}
