@import 'styles/variables';

.topSection {
  padding: spacing(6) spacing(4);
}

.checkbox {
  margin: spacing(4) 0;
}

.tagsRow {
  display: flex;
  :not(:first-child) {
    margin-left: spacing(2);
  }
}

.deadline {
  font-size: $font-size-16;

  &.error {
    color: $color-error;
  }
}

.searchInput {
  margin-top: spacing(2);
  .searchInnerContainer {
    input {
      width: 328px;
    }
  }
}