@import 'styles/variables';
@import 'styles/mixins';

.sidebarContainer {
  width: 208px;
  flex-shrink: 0;
  background-color: $color-primary;
  scrollbar-width: none;
  transition: width .1s ease-out;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;

  .collapsedIcon {
    display: none;
  }

  .expandedIcon {
    display: initial;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .menuList {
    display: flex;
    flex-direction: column;
    flex: 1;

    > li:nth-last-child(2) {
      margin-top: auto;
    }
  }

  .listItem {
    height: 48px;
    color: $color-white;
    font-size: $font-size-14;
    width: 100%;
    display: flex;
    align-items: center;
    padding: spacing(3.5) spacing(2);
    box-shadow: inset 0px -1px 0px $color-submenu-background;


    &:hover {
      background-color: $color-secondary;
    }

    &:active {
      background-color: $color-secondary-light;
    }

    &:focus-visible {
      outline: $color-white 2px solid;
      width: calc(100% - 6px);
      margin-left: 3px;
      outline-offset: unset;
    }

    &[aria-current=page] {
      background: $color-secondary-light;
    } 

    .itemIcon {
      margin-right: spacing(2);
    }

    span {
      flex: 1;
      text-align: left;
      // Weird hack needed to match design.
      margin-top: 1px;
    }

    .dropdownIcon {
      transform: rotate(180deg);
    }

    &[aria-expanded=true] {

      .dropdownIcon {
        transform: rotate(0deg);
      }

      + .subMenu {
        display: block;
      }
    }

    &.interListItem {
      border-top: 2px solid $color-white;
      border-bottom: 2px solid $color-white;
      > span {
        text-transform: uppercase;
        @include h6TextStyle;
      }
    }
  }

  .subMenu {
    display: none;

    &:not(.interSubMenu) {
      .listItem {
        background-color: $color-submenu-background;
        box-shadow: inset 0 -1px 0 $color-submenu-shadow;
        padding-left: spacing(8);
      }
    }
  }

  &.collapsed {
    width: 56px;
    overflow-x: hidden;

    .collapsedIcon {
      display: initial;
    }

    .expandedIcon {
      display: none;
    }

    .listItem {
      justify-content: center;
      span {
        display: none;
      }

      svg {
        width: 24px;
        height: 24px;
      }

      svg path {
        fill: $color-white;
      }

      .itemIcon {
        margin-right: 0;
      }


    &[aria-expanded=true] {
      + .subMenu {
        display: none;
      }
    }

      &.interListItem {
        display: none;

        &[aria-expanded=false],
        &[aria-expanded=true] {
          + .subMenu {
            display: block;
          }
        }
      }
    }
  }
}
