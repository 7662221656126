@import 'styles/variables';

.mainContainer {
  display: flex;
  min-height: 100vh;

  .contentContainer {
    position: relative;
    width: 100%;
    overflow-x: hidden;

    .scrollableContent {
      height: calc(100vh - $header-height - $breadcrumb-height);
      width: 100%;
      overflow-x: hidden;
      overflow-y: scroll;
      padding: spacing(6);
    }
  }
}
