@import 'styles/variables';
@import 'styles/mixins';

.container {
  background: $color-white;
  padding: spacing(6) spacing(6) spacing(8) spacing(6);
  border: 1px solid $color-border;
  border-top: unset;
  box-shadow: 0 4px 10px $color-shadow-main;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;

  h2 {
    @include h3TextStyle;
    margin-bottom: spacing(8);
  }

  .formButtons {
    grid-column: 1/3;
  }

  .formContainer {
    display: grid;
    grid-gap: spacing(4);
    grid-template-columns: 1fr 1fr;
  }
}

.deleteButton {
  color: $color-error;
  margin-left: auto;
  margin-bottom: spacing(6);
}

.deleteButton svg path {
  fill: $color-error;
}
