@import 'styles/variables';

.errorContainer {
  position: absolute;
  display: flex;
  align-items: center;
  padding: spacing(1.5) spacing(10) spacing(1.5) spacing(1);
  white-space: nowrap;
  width: fit-content;
  top: calc(100% + spacing(2));
  left: 0;
  background-color: $color-error-light;
  z-index: 1;
  filter: drop-shadow(0px 1px 5px $color-shadow-secondary);

  &:before {
    content:"\A";
    border-style: solid;
    border-width: 0 6px 8px 6px;
    border-color: transparent transparent $color-error-light transparent;
    position: absolute;
    left: spacing(2);
    top: spacing(-2);
  }

  span {
    margin-left: spacing(2);
    font-size: $font-size-13;
    b {
      font-weight: $font-weight-bold;
    }
  }
}