@import 'styles/mixins';

.cellValue {
  @include labelTextStyle;
}

.label {
  @include labelTextStyle;
  margin-right: spacing(2.5);
}

.row {
  display: flex;
}