@import 'styles/variables';
@import 'styles/mixins';

.titleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: spacing(4);

  .buttonsContainer {
    display: flex;
    > :first-child {
      margin-right: spacing(2);
    }
  }
}

.dateText {
  @include bodyTextSmallStyle;
  color: $color-input-main;
  &:first-of-type {
    margin-top: spacing(2);
  }
}

.archiveContent {
  color: $color-primary;
}

.deactivationDate {
  display: grid;
  grid-auto-flow: column;
  grid-gap: spacing(2);
  align-items: center;
  width: max-content;
  padding: spacing(6) 0 spacing(8) 0;

  &.hidden {
    display: none;
  }

  span {
    @include bodyTextStyle;
    color: $color-error;
  }

  .button {
    padding: spacing(1);
    svg {
      width: 20px;
      height: 21px;
      path {
        fill: $color-error;
      }
    }
    &:focus {
      box-shadow: inset 0 0 0 2px $color-error;
    }
  }
  .editIcon {
    padding: spacing(1);
    svg {
      width: 21px;
      height: 20px;
    }
  }
}

:global(.react-datepicker__tab-loop) {
  position: fixed;
  z-index: 2;
}

.deactivateDynamicForm {
  padding-top: spacing(6);
}

.deactivateContent > div > div > div,
.activateContent > div > div > div {
  color: $color-primary;
  max-width: 70%;
}

.activateContent h1,
.deactivateContent h1 {
  max-width: 70%;
}

.activateDynamicForm {
  padding-top: spacing(5);
  padding-bottom: spacing(10);
}

.checkboxInputClass {
  padding-top: spacing(4);
}

.userForm {
  margin-top: spacing(6);
}
