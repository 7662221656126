@import 'styles/variables';

.topSection {
  padding: spacing(6) spacing(4);
}

.container {
  padding-top: spacing(2);
}

.generalPriceListInput input {
  min-width: 328px;
}
