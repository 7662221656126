@import 'styles/variables';

.timeColumnContainer {
  display: flex;
  border: 1px solid $color-input-border;
  border-radius: 5px;
  height: 106px;
  max-width: 200px;
  width: fit-content;
  background-color: $color-white;
  position: absolute;
  z-index: 2;

  &:focus {
    box-shadow: inset 0 0 0 2px $color-primary;
  }
}
