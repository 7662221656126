@import 'styles/variables';
@import 'styles/mixins';

.peopleContainer {
  display: grid;
  grid-template-columns: minmax(auto, 409px) minmax(auto, 409px);
  grid-gap: spacing(10);
  padding: spacing(4);
  padding-bottom: spacing(8);
  border-radius: $border-radius-4;
}

.toolTip {
  margin-right: auto;
  margin-left: spacing(4);
}

.detailsContainer {
  display: grid;
  grid-template-columns: minmax(auto, 409px) auto;
  grid-column-gap: spacing(10);
  padding: spacing(4);
  padding-bottom: spacing(8);
  margin-top: spacing(2);
  border-radius: $border-radius-4;

  h2 {
    @include h3TextStyle;
    color: $color-primary;
    padding-left: spacing(4);
    margin-bottom: spacing(4);
  }
}

.formButtons {
  display: flex;
  justify-content: space-between;
  margin-top: spacing(8);
}

.wrapper {
  margin-top: spacing(2);
  &.existingProjectWrapper {
    .formButtons {
      margin-top: spacing(2);
      justify-content: flex-end;
      grid-column: 1/3;

      button:last-child {
        margin-left: spacing(2);
      }
    }
  }

  &.viewModeWrapper {
    margin-top: unset;
    background: $color-white;
    border: 1px solid $color-border;
    border-radius: 0 0 $border-radius-4 $border-radius-4;
    box-shadow: 0 0 20px 0 rgba(0, 39, 69, 0.10);
    padding: spacing(2);
    border: unset;

    .detailsContainer {
      h2 {
        padding-left: unset;
      }
    }

    .formButtons {
      padding-bottom: spacing(4);
    }

    .peopleContainer,
    .detailsContainer {
      grid-template-columns: 1fr 1fr;;
      border: unset;
      box-shadow: unset;
      border-radius: unset;
      padding-bottom: unset;
    }
  }
}

.expandableContentTitle {
  @include h3TextStyle;
  color: $color-input-main;
}
