@import 'styles/variables';

.assignmentContainer {
  display: grid;
  grid-template-columns: minmax(auto, 409px) auto;
  grid-column-gap: spacing(10);
  margin-top: spacing(4);
  border-top: 1px solid $color-border-secondary;
  padding-top: spacing(8);

  h3 {
    color: $color-primary;
  }
}

.assignmentId {
  font-weight: $font-weight-bold;
  margin-top: spacing(2);
}

.addButton {
  margin-top: spacing(6);
}

.formButtons {
  grid-column: 1/3;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: spacing(6);
  margin-bottom: spacing(4);

  > :last-child {
    margin-left: spacing(2);
  }
}

.deleteButton {
  padding-left: spacing(2);
  display: flex;
  align-items: center;

  &:focus {
    box-shadow: inset 0 0 0 2px $color-error;
  }
}

.titleContainer {
  display: flex;
}
