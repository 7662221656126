@import 'styles/variables';
@import 'styles/mixins';

.content {
  z-index: 3;
  position: absolute;
  border-radius: 4px;
  width: 188px;
  background-color: $color-white;
  box-shadow: 0px 4px 10px 0px $color-shadow-main;
  border: 1px solid $color-border;
  padding: spacing(2);
  width: 188px;
  top: 100%;
  li:not(:first-child) {
    margin-top: spacing(2);
  }

  &:focus {
    box-shadow: inset 0 0 0 2px $color-primary;
  }

  li {
    span {
      color: $color-text-dark;
    }
  }
}


.container {
  display: flex;
  flex-direction: column;
  position: relative;
  
  label {
    @include bodyTextSmallStyle;
    font-weight: $font-weight-light;
  }

  .iconButton {
    svg {
      width: 14px;
      padding-left: 0;
      margin-left: spacing(2);
    }
  }

  &.open {
    .iconButton {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}