@import 'styles/variables';
@import 'styles/mixins';

.formContainer {
  display: flex;
  flex-direction: column;

  .selectInternalPosition {
    justify-content: flex-end;
    margin-bottom: spacing(4);
    label {
      text-align: end;
    }
  }

  .inputInternalPosition {
    justify-content: flex-end;
    margin-bottom: spacing(4);

    label {
      text-align: end;
    }

    input:first-of-type,
    textarea {
      width: 274px;
    }
  }

  .inputSearch {
    justify-content: flex-end;
    margin-bottom: spacing(4);

    > label {
      text-align: end;
    }
  }

  .customInternalClass {
    justify-content: flex-end;
    margin-bottom: spacing(4);

    label {
      text-align: end;
    }

    & > div {
      width: 274px;
      justify-content: space-between;
    }
  }

  .grayRow {
    justify-content: space-between;
    margin-left: spacing(13);
    span {
      color: $color-input-main;
    }
    :last-child {
      font-weight: $font-weight-normal;
    }
  }

  &.adjustedLayout {
    .inputInternalPosition,
    .customInternalClass,
    .inputSearch {
      margin-bottom: spacing(2);
      display: grid;
      grid-template-columns: 1fr 1fr;

      :last-child {
        font-weight: $font-weight-bold;
      }

      &.grayRow {
        justify-content: flex-end;
        margin-left: 0;
      }
    }
  }
}
