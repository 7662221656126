@import 'styles/variables';
@import 'styles/mixins';

.topSection {
  margin-top: spacing(6);
}

.checkbox {
  margin-top: spacing(4);
}

.tagsRow {
  display: flex;
  :not(:first-child) {
    margin-left: spacing(2);
  }
}

.deadline {
  font-size: $font-size-16;

  &.error {
    color: $color-error;
  }
}

.noTasks {
  @include subtitleSmallTextStyle;
  color: $color-text;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: spacing(14);
}
