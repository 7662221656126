@import 'styles/variables';

.tagsContainer {
  display: flex;
  width: fit-content;
  flex-wrap: wrap;
}

.tag {
  border: 1px solid $color-border;
  border-right: 0;
  margin-bottom: spacing(2);

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:focus {
    box-shadow: inset 0 0 0 1px $color-primary;
  }
}
