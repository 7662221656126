@import 'styles/variables';
@import 'styles/mixins';

.labelClass {
  justify-self: end;
}

.column {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: repeat(6, max-content);
  grid-column-gap: spacing(2.5);
  grid-row-gap: spacing(4);
  justify-items: left;
  align-items: center;

  &.noRows {
    grid-template-rows: unset;
  }

  h2 {
    grid-column: 1 / 3;
    @include h3TextStyle;
    color: $color-primary;
  }

  .hidden {
    display: none;
  }

  .boldText {
    font-weight: $font-weight-bold;
    span {
      font-weight: $font-weight-bold;
    }
  }

  &.adjustedLayout {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 44px;
    grid-auto-rows: minmax(24px, auto);
    grid-row-gap: spacing(2);

    .labelClass {
      justify-self: unset;
    }

    .extraPadding {
      padding-left: unset;
    }

    h2 {
      margin-bottom: spacing(2);
      align-self: start;
    }
  }

  .extraPadding {
    padding-left: spacing(4);
  }
}
