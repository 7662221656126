@import 'styles/variables';
@import 'styles/mixins';

.container {
  min-height: 100vh;
  padding: 0 spacing(26);
  display: flex;
  align-items: center;
  background-color: $color-white;

  @media (max-width: $small_desktop) {
    padding: 0 spacing(16);
  }

  @media (max-width: $mobile) {
    padding: 0 spacing(4);
  }
}
