@import 'styles/variables';
@import 'styles/mixins';

.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $small_desktop) {
    display: grid;
    justify-items: center;
    grid-gap: spacing(12);
  }

  // TODO: currently will leave it as is, since this is using some weird values
  .whiteBackground {
    position: absolute;
    background: $color-white;
    height: 216px;
    width: 100%;
    z-index: -1;
  }

  .background {
    margin-left: spacing(16);
    width: 386px;
    height: 275px;

    @media (max-width: $tablet) {
      margin: auto;
      width: 230px;
      height: 165px;
    }
  }

  .contentContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-left: spacing(22);
    margin-right: spacing(8);

    .buttonsContainer {
      margin-top: spacing(4);
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: spacing(2);

      @media (max-width: $medium_mobile) {
        grid-template-columns: 1fr;
      }
    }

    h1,
    .text {
      text-align: end;
    }

    .text {
      margin: spacing(11) 0 spacing(1.5) 0;
    }

    @media (max-width: $small_desktop) {
      margin: 0;
      align-items: start;

      h1,
      .text {
        text-align: start;
      }
      .buttonsContainer {
        margin-top: spacing(8);
      }
      .bigButton {
        padding: spacing(1.5) spacing(9.5);
      }
    }
  }

  .button {
    justify-content: center;
    padding: spacing(2) spacing(6);
  }
}

.logo {
  width: 125px;
  height: 72px;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: spacing(8);
}

.link {
  display: inline;
  span {
    font-size: 20px;
    font-weight: 400;
  }
}
