@import 'styles/variables';
@import 'styles/mixins';

.container {
  grid-column: 1/-1;
  border-radius: $border-radius-4;
  padding: spacing(4);

  .titleRow {
    display: grid;
    grid-template-columns: max-content max-content max-content auto;
    grid-gap: spacing(2);
    align-items: center;
  }

  .title {
    color: $color-primary;
    padding: spacing(3) spacing(4);
  }

  .deleteButton {
    @include bodyTextSmallStyle;
    color: $color-error;
    padding: spacing(1);
    justify-self: end;

    svg {
      width: 20px;
      height: 21px;

      path {
        fill: $color-error;
      }
    }
    &:focus {
      box-shadow: inset 0 0 0 2px $color-error;
    }
  }
}
