@import 'styles/variables';
@import 'styles/mixins';

.tagsHeader {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  grid-gap: spacing(4);
}

.tagsContainer {
  grid-template-columns: auto auto auto;
  margin-top: spacing(6);
  padding: spacing(6) spacing(8);
  grid-gap: spacing(2);
}

.addingTag {
  color: $color-primary;
  padding-bottom: spacing(4);
}

.tagsSection {
  display: grid;
  border-left: 1px solid $color-background-dark;
  padding-left: spacing(4);
}

.categorySection {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.tagInputField {
  p {
    color: $color-text-dark;
  }
  input {
    width: 508px;
    height: 100%;
    padding: spacing(2);
  }
}

.addButton {
  margin-top: spacing(4);
}

.categoryContainer {
  display: flex;
  flex-wrap: wrap;
  padding-top: spacing(8);
  & > * {
    margin-right: spacing(6);
  }
}
