@import 'styles/variables';
@import 'styles/mixins';

.wrapper {
  display: flex;
  align-items: center;
}

.label {
  @include labelTextStyle;
  display: inline-block;
  padding-right: spacing(2.5);
  text-align: end;
}

.innerWrapper {
  display: flex;
  width: 274px;
  justify-content: space-between;

  .timePicker {
    width: unset;
  }
}