@import 'styles/variables';
@import 'styles/mixins';

.formContainer {
  display: flex;
  flex-direction: column;
  margin-top: spacing(6);

  .inputInternalPosition {
    justify-content: flex-end;
    margin-bottom: spacing(4);

    label {
      text-align: end;
    }
    input,
    textarea,
    > span:nth-child(2) {
      width: 274px;
      display: block;
    }

    > span:first-child {
      text-align: right;
    }
  }

  .customInternalClass {
    justify-content: flex-end;
    margin-bottom: spacing(4);

    label {
      text-align: end;
    }

    & > div {
      width: 274px;
      justify-content: space-between;
    }
  }
}