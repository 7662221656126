@import 'styles/variables';
@import 'styles/mixins';

.wrapper {
  display: flex;
  align-items: flex-start;
}

.label {
  @include labelTextStyle;
  display: inline-block;
  padding-right: spacing(2.5);
  text-align: end;
}

.innerWrapper {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: spacing(2);
  align-items: start;
}

.button {
  margin-left: spacing(2);
  padding: spacing(2);

  svg {
    width: 20px;
    height: 21px;
    padding-left: 0;

    path {
      fill: $color-error;
    }
  }
  &:focus {
    box-shadow: inset 0 0 0 2px $color-error;
  }
}
