@import 'styles/variables';

.expandableContainer {
  border-left: spacing(1) solid;
  &:not(:first-child) {
    margin-top: spacing(4);
  }
  &.new {
    border-color: $color-warning;
  }
  &.registered {
    border-color: $color-highlight;
  }
  &.tasks_submitted_to_vendors {
    border-color: $color-success;
  }
  &.tasks_in_progress {
    border-color: $color-primary;
  }
  &.tasks_completed {
    border-color: $color-success;
  }
  &.cancelled {
    border-color: $color-border;
  }
  &.completed {
    border-color: $color-success;
  }

  .startWorkFlowNotification {
    border-color: transparent;
    background-color: $color-tag;
    color: $color-primary;
    justify-content: space-between;

    p {
      max-width: 561px;
      font-size: $font-size-14;
    }

    button {
      width: fit-content;
    }

    &.warning {
      background-color: $color-warning-light;
      border-color: $color-warning;
      color: $color-text;
    }
  }

  .notificationStyle {
    display: flex;
    width: 100%;
    padding: spacing(2) spacing(4);
    margin: spacing(4) 0 spacing(2);
  }
}
