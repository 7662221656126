@import 'styles/variables';
@import 'styles/mixins';

.wrapper {
  display: flex;
  align-items: center;
}

.label {
  @include labelTextStyle;
  display: inline-block;
  padding-right: spacing(2.5);
  text-align: end;
}

.innerWrapper {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  grid-gap: spacing(2);
  align-items: center;

  .line {
    width: 8px;
    height: 0;
    border: 1px solid $color-border-secondary;
    margin: 3px;
    display: inline-block;
  }
}
