@import 'styles/variables';
@import 'styles/mixins';

.dropdownMenu {
  background: $color-white;
  border: 1px solid $color-border;
  border-radius: 0px 0px 4px 4px;
  position: absolute;
  top: 40px;
  max-height: 35vh;
  display: flex;
  flex-direction: column;

  ul {
    overflow: auto;
    flex: 1;

    &:focus {
      box-shadow: inset 0 0 0 2px $color-primary;
    }
  }
}

.dropdownMenuItem {
  cursor: pointer;
  list-style-type: none;
}

.option {
  @include labelTextStyle;
  padding: spacing(2);
  text-align: unset;
  width: 100%;
  height: 40px;
}

.selectedOption {
  @include subtitleTextStyle;

  &:focus {
    @include subtitleTextStyle;
  }
}

.buttonsContainer {
  display: flex;
  padding: spacing(3) 0;
  justify-content: center;
  box-shadow: 0 -1px 1px $color-shadow-secondary;
}

.l {
  width: 274px;
}

.m {
  width: 150px;
}

.s {
  width: 77px;
}
.xs {
  width: 47px;
  top: 32px;
}

.dropdownButton {
  margin-left: spacing(2);
}

.searchInput {
  margin: spacing(2);
  input {
    width: 258px;
  }
}
