@import 'variables.scss';

// typography mixin

@mixin h1TextStyle {
  font-size: $font-size-32;
  font-weight: $font-weight-light;
  @media (max-width: $mobile) {
    font-size: $font-size-24;
  }
}

@mixin h2TextStyle {
  font-size: $font-size-28;
  font-weight: $font-weight-normal;
  @media (max-width: $mobile) {
    font-size: $font-size-20;
  }
}

@mixin h3TextStyle {
  font-size: $font-size-24;
  font-weight: $font-weight-normal;
  @media (max-width: $mobile) {
    font-size: $font-size-19;
  }
}

@mixin h4TextStyle {
  font-size: $font-size-20;
  font-weight: $font-weight-normal;
}

@mixin h5TextStyle {
  font-size: $font-size-18;
  font-weight: $font-weight-normal;
}

@mixin h6TextStyle {
  font-size: $font-size-16;
  font-weight: $font-weight-bold;
}

@mixin bodyTextStyle {
  font-size: $font-size-16;
}

@mixin bodyTextSmallStyle {
  font-size: $font-size-14;
}

@mixin subtitleTextStyle {
  font-size: $font-size-16;
  font-weight: $font-weight-bold;
}

@mixin subtitleSmallTextStyle {
  font-size: $font-size-14;
  font-weight: $font-weight-normal;
}

@mixin buttonTextStyle {
  font-size: $font-size-16;
  font-weight: $font-weight-normal;
}

@mixin labelTextStyle {
  font-size: $font-size-16;
  font-weight: $font-weight-normal;
  color: $color-text-dark;
}

@mixin smallButtonTextStyle {
  font-size: $font-size-12;
  font-weight: $font-weight-normal;
  line-height: $line-height-14;
}

@mixin badgeEndTriangles($color) {
  background-color: $color;
  &:after {
    content: '';
    position: absolute;
    left: 100%;
    bottom: 0;
    top: 0;
    border-top: 19px solid transparent;
    border-bottom: 18px solid transparent;
    border-left: 19px solid $color;
  }
}

@mixin statusTagStyle($color, $text-color: $color) {
  color: $text-color;
  box-shadow: 0 0 0 2px $color;
  padding: spacing(0.5) spacing(4);
  border-radius: 4px;
  min-height: 25px;
}

@mixin manualStyle($background-color: $color) {
  p {
    white-space: break-spaces;
    padding-bottom: spacing(6);
    &:last-child {
      padding-bottom: 0;
    }
  }

  a {
    color: $color-primary;
    text-decoration: underline;
  }

  ul {
    display: flex;
    flex-direction: column;

    li {
      display: inline-block;
      margin-left: 20px;
      position: relative;

      &::before {
        content: '\2022';
        position: absolute;
        left: -15px;
      }
    }
  }
}
