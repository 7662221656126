@import 'styles/variables';

.clientContentContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: spacing(24);
  border: 1px solid $color-border-sub-project-content;
  border-radius: 4px;
  padding: spacing(1);
  padding-bottom: spacing(5);
  margin-top: spacing(5);
}

.filesListContainer {
  box-shadow: unset;
  border: unset;
  padding: spacing(4) spacing(5);
  background-color: $color-background-card;
  align-self: start;

  .iconButton {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    svg {
      width: 16px;
      height: 16px;
    }
  }
  
  .downloadButton {
    svg {
      path {
        fill: $color-primary;
      }
    }
  }

  .row {
    display: flex;
  }

  .innerTooltip {
    margin-left: spacing(2);
  }

  &.increasedSpecificity {
    thead {
      tr {
        th {
          padding: unset;
          padding-right: spacing(8);
          border: transparent;
          vertical-align: top;
          height: 40px;
          min-height: 40px;
          &:first-child {
            min-width: 78px;
            max-width: 78px;
            width: 78px;
            white-space: pre-wrap;
            padding-right: spacing(1);
          }
        }
      }
    }

    tbody {
      tr {
        td {
          padding: unset;
          padding-right: spacing(8);
          border: transparent;
          height: 32px;
          max-height: 32px;
      
          &:first-child {
            padding-right: spacing(1);
            width: 78px;
            font-weight: $font-weight-bold;
          }
          &:last-child {
            padding: 0;
          }
        }
      }
    }
  }


  .fitContent {
    width: fit-content;
  }
}


.titleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: spacing(4);
  color: $color-primary;
}

.assignmentContainer {
  display: flex;
  flex-direction: column;
  padding-left: spacing(4);
  padding-top: spacing(4);

  h3 {
    margin-bottom: spacing(4);
    color: $color-primary;
  }

  .row {
    display: flex;
    justify-content: space-between;
    .label {
      font-size: $font-size-16;
      width: 260px;
    }
    .value {
      font-weight: $font-weight-bold;
    }
    &:not(:last-child) {
      margin-bottom: spacing(2);
    }
  }
}

.tabsContainer {
  margin-top: spacing(8);
  margin-bottom: spacing(4);
}