@import 'styles/variables';

.expandableContainer {
  border-left: spacing(1) solid;
  &:not(:first-child) {
    margin-top: spacing(4);
  }
  &.registered {
    border-color: $color-highlight;
  }
  &.new {
    border-color: $color-warning;
  }
  &.tasks_submitted_to_vendors {
    border-color: $color-success;
  }
  &.tasks_in_progress {
    border-color: $color-primary;
  }
  &.tasks_completed {
    border-color: $color-success;
  }
  &.done_task {
    border-color: $color-success;
  }
  &.cancelled {
    border-color: $color-border;
  }
  &.completed {
    border-color: $color-warning;
  }
}
