@use 'reset';
@import './variables.scss';
@import './mixins.scss';

@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  src:
    local('Roboto'),
    url(./Roboto/Roboto-Bold.ttf) format('truetype'),
    url(./Roboto/Roboto-BoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src:
    local('Roboto'),
    url(./Roboto/Roboto-Regular.ttf) format('truetype'),
    url(./Roboto/Roboto-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  src:
    local('Roboto'),
    url(./Roboto/Roboto-Light.ttf) format('truetype'),
    url(./Roboto/Roboto-LightItalic.ttf) format('truetype');
}

body {
  background-color: $color-background-main;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#root {
  pointer-events: auto;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
button,
input,
select,
textarea {
  box-sizing: border-box;
  line-height: $line-height;
  font-family: 'Roboto', sans-serif;
}

button > * {
  pointer-events: none;
}

a {
  color: inherit;
  text-decoration: none;
}

h1 {
  @include h1TextStyle;
}

h2 {
  @include h2TextStyle;
}

h3 {
  @include h3TextStyle;
}

h4 {
  @include h4TextStyle;
}

h5 {
  @include h5TextStyle;
}

h6 {
  @include h6TextStyle;
}

p {
  @include bodyTextStyle;
}
