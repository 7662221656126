@import 'styles/variables';
@import 'styles/mixins';

.container {
  flex: 1;

  .generateSection {
    margin-top: spacing(4);
  }
}

.filesListContainer {
  box-shadow: unset;
  border: unset;

  .iconButton {
    display: flex;
    svg {
      width: 21px;
      height: 20px;
    }
    &:focus {
      box-shadow: inset 0 0 0 2px $color-primary;
    }

    &.disabled {
      cursor: not-allowed;
      svg path {
        fill: $color-background-disabled;
      }
      &:focus {
        box-shadow: inset 0 0 0 2px $color-error;
      }
    }

    .downloadButton {
      svg {
        path {
          fill: $color-primary;
        }
      }
      &:focus {
        box-shadow: inset 0 0 0 2px $color-primary;
      }
    }

    .row {
      display: flex;
    }

    .innerTooltip {
      margin-left: spacing(2);
    }

    tbody {
      tr {
        td {
          &:nth-last-child(2),
          &:last-child {
            padding: spacing(1);
          }
        }
      }
    }

    .fitContent {
      width: fit-content;
    }
  }
}

.titleRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: spacing(4);

  .fileImportButton {
    margin-left: auto;
  }

  h3 {
    @include h4TextStyle;
    margin-right: spacing(2);
  }
}

.filesTable {
  flex: 1;
}
