@import 'styles/variables';
@import 'styles/mixins';

.timeInput {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: $color-white;
  border: 1px solid $color-input-border;
  border-radius: 4px;
  color: $color-input-border;
  @include buttonTextStyle;
  height: 40px;
  padding: spacing(2);

  &:focus {
    color: $color-text-dark;
    border-color: $color-primary;
  }
  &.error {
    border: 1px solid $color-error;
    border-radius: 5px;
  }
}

.disabledTimeInput {
  background-color: $color-background;
  cursor: not-allowed;
}

.disabledTimeInput:focus {
  color: $color-input-border;
  border-color: $color-input-border;
}

.timeIcon {
  position: absolute;
  right: 8px;
  top: 8px;
  pointer-events: none;
}

.disabledIcon path {
  fill: $color-input-border;
}

.timePickerWrapper {
  width: 125px;
  position: relative;
}
