@import 'styles/variables';
@import 'styles/mixins';

.container {
  position: absolute;
  height: 146px;
  z-index: 4;
}

.dragAndDropContainer {
  height: 100%;
  background: $color-text-button;
  border: 1px solid $color-border-black-coral;
  border-radius: 5px;
  width: 318px;
  padding: spacing(4) spacing(4) spacing(2) spacing(4);
  position: relative;
  box-shadow: 0px 0px 2px $color-shadow-main, 0px 2px 2px $color-checkbox-focus,
    0px 1px 3px $color-shadow-secondary;

  &::after {
    content: ' ';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 10px solid $color-text-button;
    margin-top: 6px;
    filter: drop-shadow(0px 2px 2px $color-shadow-secondary);
  }

  &.rightIndicator {
    &::after {
      margin-left: calc(100% - 48px);
    }
  }
}

.dragAndDropContent {
  border: 1px dashed $color-border-secondary;
  border-radius: 2px;
  padding: spacing(3.5) 0 spacing(5) 0;
  text-align: center;
}

.dragAndDropText {
  @include bodyTextSmallStyle;
  padding-top: spacing(0.5);
  display: inline-flex;
}

.dropButton {
  margin-left: spacing(2);
}

.multipleFilesText {
  font-size: $font-size-12;
  font-weight: $font-weight-normal;
  padding-top: spacing(1);
  height: spacing(6);
}
