@import 'styles/variables';

.tooltipWrapper {
  position: relative;
  display: flex;
}

.tooltipContent {
  background: $color-text-button;
  text-align: center;
  border-radius: 5px;
  width: 230px;
  padding: spacing(1);
  position: absolute;
  bottom: calc(100% + spacing(2));
  left: spacing(-6);
  z-index: 5;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  display: none;
  word-break: break-word;
  white-space: normal;

  &.bottomPosition {
    bottom: unset;
    top: spacing(8);

    &::after {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid $color-text-button;
      border-top: unset;
      margin-top: spacing(-2);
      margin-left: spacing(6);
      filter: drop-shadow(0px -2px 2px $color-shadow-secondary);
    }
  }

  &::after {
    content: ' ';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid $color-text-button;
    margin-top: spacing(1);
    margin-left: spacing(6);
    filter: drop-shadow(0px 2px 2px $color-shadow-secondary);
  }
}

.tooltipWrapper:hover,
.tooltipWrapper:focus {
  .tooltipContent {
    display: block;
  }
}

.tooltipContent {
  &.visible {
    display: block;
  }
}

.infoIcon {
  height: 24px;
  width: 24px;
  g path {
    fill: $color-primary;
  }
}
