@import 'styles/variables';

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: spacing(4);
  margin-top: spacing(8);

  .catJobs {
    margin-top: spacing(6);
  }
}

.translationMemories {
  grid-column: 1/3;
  div button h3 {
    color: $color-input-main;
  }
}

.taskDetailsContainer {
  flex-direction: column;
  display: inline-flex;
}

.taskContainer {
  display: flex;
  justify-content: flex-end;
  padding-top: spacing(6);

  .taskDetails,
  .specialInstructions label {
    text-align: end;
    max-width: 125px;
  }

  .taskContent {
    font-weight: $font-weight-bold;
    display: flex;
  }

  .taskContent,
  .specialInstructions textarea {
    width: 274px;
  }

  .taskContent,
  .specialInstructions {
    margin-left: spacing(2.5);
  }
}

.myNotesLabel {
  margin-right: 0;
  font-weight: $font-weight-bold;
}

.myFinalFiles section div h3 {
  color: $color-primary;
}

.volumeIcon {
  padding-left: spacing(2);
}

.taskViewPrices div table tbody tr td div {
  width: 127px;
}

.hideContainer {
  display: none;
}

.finishedButton,
.previousButton {
  margin-top: spacing(6);
  margin-bottom: spacing(4);
  float: inline-end;
}

.finishedButton {
  margin-left: spacing(2);
}

