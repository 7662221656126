@import 'styles/variables';

.sectionHeader {
  display: grid;
  grid-template-rows: auto auto;
  align-items: start;

  &.hidden {
    display: none;
  }

  &.noBorder {
    border-bottom: unset;
    padding-bottom: unset;
  }

  .addButton {
    margin-top: spacing(2);
  }

  .toggleButtons {
    justify-self: end;
    grid-row: 1/3;
    grid-column: 2;
    button:first-child {
      margin-bottom: spacing(2);
    }
  }

  .splitSection {
    display: flex;
    align-items: flex-end;
  }

  .smallTooltip {
    padding-left: spacing(2);
  }
}
