@import 'styles/variables';
@import 'styles/mixins';

.container {
  display: flex;
  align-items: flex-start;

  .valuesContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 274px;
  }

  .label {
    @include labelTextStyle;
    padding-right: spacing(2.5);

    &.hidden {
      display: none;
    }
  }
}

.row {
  display: flex;
  margin-bottom: spacing(2);

  .editButton,
  .deleteButton {
    margin-left: spacing(2);
    padding: spacing(1);
    display: flex;

    &.deleteButton:focus {
      box-shadow: inset 0 0 0 2px $color-error;
    }
    &.editButton:focus {
      box-shadow: inset 0 0 0 2px $color-primary;
    }
    &.disabledIcon {
      cursor: not-allowed;
      svg path {
        fill: $color-background-disabled;
      }
    }
  }
}
