@import 'styles/variables';

.topSection {
  padding: spacing(6) spacing(4);
}

.checkbox {
  margin-top: spacing(4);
}

.tagsRow,
.languageRow {
  display: flex;
  :not(:first-child) {
    margin-left: spacing(2);
  }
  max-width: spacing(44);
  overflow: hidden;
}

.deadline {
  font-size: $font-size-16;

  &.error {
    color: $color-error;
  }
}

.tagsRow {
  width: 266px;
  overflow: hidden;
}

.languageRow {
  width: 160px;
  overflow: hidden;
}
// Alternative solution for showing all language pairs
// .languageRow {
//   flex-wrap: wrap;
// }
