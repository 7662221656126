@import 'styles/variables';
@import 'styles/mixins';

.topSection {
  padding: spacing(6) spacing(4);
}

.modalTable {
  border: none;
  box-shadow: none;
  margin: spacing(6) 0 0;
}

.tagsRow {
  display: flex;
  :not(:first-child) {
    margin-left: spacing(2);
  }
}

.deadline {
  font-size: $font-size-16;

  &.error {
    color: $color-error;
  }
}

.legend {
  display: flex;
  align-items: center;
  margin-bottom: spacing(2);
}

.padding {
  padding: 0 spacing(14);
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  background-color: #bbb;

  &.private {
    background-color: $color-highlight;
  }
  &.shared {
    background-color: $color-success;
  }
  &.public {
    background-color: $color-secondary;
  }
}
.statusName {
  margin: 0 spacing(6) 0 spacing(2);
  @include bodyTextSmallStyle;
}

.searchInput {
  margin-top: spacing(4);
  .searchInnerContainer {
    input {
      width: 328px;
    }
  }
}
