@import 'styles/variables';
@import 'styles/mixins';

.control {
  display: grid;
  padding: spacing(1) spacing(3.5);

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.controlTimeTop,
.controlTimeBottom {
  justify-content: center;
  align-items: center;
  display: flex;
}

.controlTimeTop svg {
  transform: rotate(270deg);
}

.controlTimeBottom svg {
  transform: rotate(90deg);
}

.controlTimeTop svg g path,
.controlTimeBottom svg g path {
  fill: $color-border-secondary;
}

.selector {
  height: 40px;
  width: 34px;
  background-color: $color-white;
  border: 1px solid $color-input-border;
  border-radius: 5px;
  text-align: center;
}

.timeWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(-43px);
  margin-top: spacing(11);
}

.selected {
  color: $color-text-dark;
  @include buttonTextStyle;
}

.focusTimeColumnButton:focus svg {
  box-shadow: inset 0 0 0 2px $color-navigation-icon;
}
