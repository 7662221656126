@import 'styles/variables';

.editIcon svg {
  width: 22px;
  height: 20px;
}

.languageButton {
  align-self: center;
  padding-left: spacing(6);
}
