@import 'styles/variables';
@import 'styles/mixins';

.toggleInputContainer {
  display: flex;
  align-items: center;
  padding-bottom: spacing(1.5);
  width: 236px;

  label {
    @include labelTextStyle;
    margin-right: spacing(2);
    text-wrap: nowrap;
  }

  .toggleContainer {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    flex: auto;

    span {
      @include bodyTextSmallStyle;
      color: $color-text-dark;
    }

    .row {
      display: flex;
      align-items: center;
      margin-left: auto;
    }

    .slider {
      position: absolute;
      top: 5px;
      bottom: 5px;
      left: 0;
      background-color: $color-input-border;
      border-radius: 14px;
      transition: 0.4s;
      width: 24px;
    }

    .slider:before {
      position: absolute;
      content: '';
      height: 10px;
      width: 10px;
      left: 2px;
      bottom: 2px;
      background-color: $color-white;
      transition: 0.4s;
      border-radius: 50%;
    }

    .switch {
      position: relative;
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-left: spacing(2);
      margin-right: spacing(2);

      &.checked {
        .slider {
          background-color: $color-success;
          &:before {
            transform: translateX(10px);
          }
        }
      }
    }

    &:focus-visible,
    &:focus {
      box-shadow: inset 0 0 0 2px $color-navigation-icon;
    }

    input {
      width: 100%;
      height: 24px;
      position: absolute;
      left: 0;
      opacity: 0;
      z-index: 1;

      &:hover,
      &:focus-visible,
      &:focus,
      &:active {
        & ~ .slider {
          box-shadow: 0 0 1px #2196f3;
        }
      }
    }

    &.disabled {
      cursor: not-allowed;

      .switch {
        &.checked {
          .slider {
            background-color: $color-input-border;
          }
        }
      }

      input {
        cursor: not-allowed;
      }
    }
  }
}
