@import 'styles/variables';
@import 'styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: $color-white;
  padding: spacing(8) spacing(6);
  border: 1px solid $color-border;
  border-radius: 6px;
  box-shadow: 0 4px 10px $color-shadow-main;

  .formButtons {
    display: flex;
    margin: spacing(3);
    justify-content: flex-end;

    > :first-child {
      margin-right: spacing(2);
    }
  }

  .inputInternalPosition {
    justify-content: flex-end;
    margin-bottom: spacing(4);
    padding-right: spacing(10);

    input,
    textarea {
      width: 274px;
    }
  }
}

.paddingRight120 {
  padding-right: 120px;
}

.formContainer {
  display: flex;
}

.deleteButton {
  color: $color-error;
  margin-left: auto;
  margin-bottom: spacing(6);
}

.datesContainer {
  margin-bottom: spacing(3);

  .dateText {
    @include bodyTextSmallStyle;
    color: $color-input-main;
    &:first-of-type {
      margin-top: spacing(2);
    }
  }
}
