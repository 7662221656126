@import 'styles/variables';
@import 'styles/mixins';

.dialogOverlay {
  background-color: $color-secondary-dark;
  opacity: 0.7;
  backdrop-filter: blur(10px);
  position: fixed;
  inset: 0;
  z-index: 3;
}

.footerButtons {
  position: relative;
  z-index: 1;
}

.dialogContent {
  background-color: $color-white;
  border: 2px solid $color-border;
  box-shadow: 0 4px 10px $color-shadow-main;
  border-radius: 4px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  padding: spacing(10) 0;
  z-index: 3;
}

.progressBarContainer {
  padding: spacing(6);

  .modalTitle {
    padding: spacing(12) spacing(6) spacing(4);
  }
  .helperText {
    padding: 0 spacing(6) spacing(4);
  }
  .spaceBetween {
    padding: spacing(4) 0 0;
    border-top: 1px solid $color-border-secondary;
  }
  .dialogDescription {
    padding: 0 spacing(6);
  }
}

.dialogDescription {
  padding: 0 spacing(18);
}

.topButton {
  position: absolute;
  top: spacing(-16);
  right: 0;
}

.scrollableContent {
  height: 100%;
  overflow: auto;
}

.extraLarge {
  max-width: 1368px;
}

.big {
  max-width: 1208px;
}

.medium {
  max-width: 980px;
}

.small {
  max-width: 672px;
}

.narrow {
  max-width: 556px;

  .scrollableContent {
    max-height: 70vh;
    height: 670px;
  }
}

.right,
.spaceBetween {
  display: flex;
  padding-top: spacing(6);
  padding-right: spacing(6);
}

.right {
  justify-content: flex-end;

  button {
    margin-left: spacing(2);
  }
}

.spaceBetween {
  padding-left: spacing(6);
  justify-content: space-between;
  align-items: center;
}

.none {
  display: none;
}

.black {
  color: $color-text-dark;
}

.gray {
  color: $color-input-main;
}

.modalTitle {
  padding: 0 spacing(18) spacing(4);
  @include h1TextStyle;
}

.helperText {
  padding: 0 spacing(18) spacing(4);
  color: $color-primary;
  @include bodyTextStyle;

  b {
    font-weight: $font-weight-bold;
  }
}
