@import 'styles/variables';

.titleRow {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  grid-gap: spacing(4);
  padding-bottom: spacing(6);
}

.buttonsContainer {
  display: flex;
}
