@import 'styles/variables';

.titleRow {
  margin-bottom: spacing(4);
  display: flex;
  align-items: center;
  
  h1 {
    margin-right: spacing(4);
  }
}