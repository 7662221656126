@import 'styles/variables';

.infoIcon {
  height: 34px;
  width: 34px;
  path {
    fill: $color-primary;
  }
}

.container {
  display: flex;

  &:focus {
    border-radius: $border-radius-20;
    box-shadow: inset 0 0 0 2px $color-navigation-icon;
  }
}
