@import 'styles/variables';

.titleRow {
  display: grid;
  grid-template-columns: max-content auto max-content;
  align-items: center;
  justify-items: start;
  grid-gap: spacing(4);
  margin-bottom: spacing(6);
}
