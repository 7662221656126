// LAYOUT

@function spacing($amount) {
  @if ($amount == 0) {
    @return 0;
  }
  @return ($amount * 4) + px;
}

// BREAKPOINTS
// NOTE: use max-width for media queries
$xs-mobile: 320px; // for solving edge cases on extra small mobiles
$mobile: 599px;
$tablet: 899px;
$laptop: 1199px;

// TYPOGRAPHY

// Font sizes
$font-size-32: 32px;
$font-size-28: 28px;
$font-size-24: 24px;
$font-size-20: 20px;
$font-size-19: 19px;
$font-size-18: 18px;
$font-size-16: 16px;
$font-size-14: 14px;
$font-size-13: 13px;
$font-size-12: 12px;

// Line heights
$line-height: 1.5;
$line-height-14: 14px;
$line-height-16: 16px;

// Font weights

$font-weight-bold: 700;
$font-weight-normal: 400;
$font-weight-light: 300;

// Border radius
$border-radius-20: 20px;
$border-radius-6: 6px;
$border-radius-5: 5px;
$border-radius-4: 4px;
$border-radius-2: 2px;

// Colors

$color-primary: #005aa3;
$color-highlight: #ff8000;
$color-dark: #4d4f5d;

$color-secondary-dark: #004277;
$color-secondary: #1769ab;
$color-secondary-light: #4587bc;

$color-submenu-background: #003a68;
$color-submenu-shadow: #00213c;

$color-text-dark: #09090b;
$color-text: #4d4f5d;
$color-text-light: #9799a4;
$color-text-button: #fdfdfd;

$color-background-dark: #e1e2e5;
$color-background: #f0f0f2;
$color-background-disabled: #d2d3d8;
$color-background-card: #f9f9f9;

$color-background-main: #f4f4f6;

$color-border: #d2d3d8;
$color-border-secondary: #5d6071;
$color-border-black-coral: #c4c5cb;

$color-success: #308653;
$color-success-light: #ecf4ef;

$color-warning: #ffb511;
$color-warning-light: #fff1d3;
$color-warning-dark: #8c630a;

$color-error: #d73e3e;
$color-error-light: #fceeee;

$color-tag: #e7f0f6;

$color-expanded-icon: #8f91a8;

$color-navigation-icon: #a2c3dd;
$color-info-light: #d0e1ee;
// Input colors
$color-input-border: #9799a4;
$color-input-main: #5d6071;

$color-border-sub-project-content: #8BB4D5;

$color-white: #ffffff;
$color-black: #000000;

$color-checkbox-hover: rgba(0, 0, 0, 0.08);
$color-checkbox-focus: rgba(0, 0, 0, 0.12);
$color-checkbox-active: rgba(0, 0, 0, 0.16);
$color-shadow-main: rgba(0, 0, 0, 0.14);
$color-shadow-secondary: rgba(0, 0, 0, 0.2);

// helpers

$header-height: 100px;
$breadcrumb-height: 48px;

// BREAKPOINTS

$medium_mobile: 360px;
$mobile: 500px;
$tablet: 768px;
$small_desktop: 1024px;
$medium_desktop: 1440px;
$medium_large_desktop: 1640px;
