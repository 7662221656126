@import 'styles/variables';
@import 'styles/mixins';

.titleRow {
  display: flex;
  align-items: center;
  margin-bottom: spacing(5);
  margin-top: spacing(7);

  h4 {
    margin-right: spacing(2);
  }
}

.emptyTableText {
  display: none;

  &.visible {
    display: block;
    @include subtitleSmallTextStyle;
  }
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: spacing(4);

  &:not(:first-child) {
    margin-top: spacing(8);
    border-top: 1px solid $color-border-secondary;
  }

  > * {
    grid-column: 1;
  }

  h3 {
    color: $color-primary;
  }

  .tableContainer {
    box-shadow: unset;
    border: unset;

    th,
    td {
      padding-left: spacing(4);
      padding-right: spacing(6);
    }

    thead {
      tr {
        th {
          &:first-child {
            min-width: 56px;
            max-width: 56px;
            width: 56px;
            padding: unset;
            text-align: center;

            > :first-child {
              display: inline-flex;
            }
          }
        }
      }
    }

    tbody {
      tr {
        td {
          &:first-child {
            min-width: 56px;
            max-width: 56px;
            width: 56px;
            padding: unset;
            text-align: center;

            > :first-child {
              display: inline-flex;
            }
          }
        }

        &:last-child {
          td {
            border-bottom: 1px solid $color-border;
          }
        }
      }
    }
  }
}

.assignmentId {
  font-weight: $font-weight-bold;
  margin-top: spacing(2);
}
