@import 'styles/variables';

.titleRow {
  display: flex;
  justify-content: space-between;
}

.projectDetails {
  margin-top: spacing(6);
}

.separator {
  height: 1px;
  width: 100%;
  margin-top: spacing(3.75);
  margin-bottom: spacing(4);
  background-color: $color-border-secondary;
}

.taskTitleContainer {
  display: flex;
  justify-content: space-between;
}

.acceptButton {
  align-self: center;
}
