@import 'styles/variables';

.formButtons {
  display: flex;
  justify-content: flex-end;
  margin-top: spacing(4);
  align-items: center;

  >:last-child {
    margin-left: spacing(2);
  }
}