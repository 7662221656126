@import 'styles/variables';

.iconButton {
  padding: spacing(1);

  svg {
    padding: 0;
    width: 20px;
    height: 20px;
  }

  svg g path,
  svg path {
    fill: $color-input-main;
  }
}

.container {
  .dropDown {
    min-width: max-content;
    left: 0;
    top: unset;
  }
}


