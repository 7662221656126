@import 'styles/variables';

.titleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: spacing(4);

  .buttonsContainer {
    display: flex;
    :first-child {
      margin-right: spacing(2);
    }
  }
}
.title {
  padding: 0 0 spacing(8) 0;
}
