@import 'styles/variables';
@import 'styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  position: relative;

  label {
    @include bodyTextSmallStyle;
    font-weight: $font-weight-light;
  }

  span {
    color: $color-primary;
  }
}
