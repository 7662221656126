@import 'styles/variables';
@import 'styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: spacing(4) spacing(6);
  background-color: $color-background-card;

  .saveButton {
    margin-top: spacing(6);
    align-self: flex-end;
  }

  .saveHelper {
    align-self: flex-end;
    font-size: $font-size-14;
    color: $color-text;
    margin-top: spacing(2);
  }
}

.filesListContainer {
  box-shadow: unset;
  border: unset;
  background-color: $color-background-card;

  .iconButton {
    display: flex;
    svg {
      width: 16px;
      height: 16px;
    }

    &.disabled {
      cursor: not-allowed;
      svg path {
        fill: $color-background-disabled;
      }
      &:focus {
        box-shadow: inset 0 0 0 2px $color-error;
      }
    }

    .downloadButton {
      svg {
        path {
          fill: $color-primary;
        }
      }
      &:focus {
        box-shadow: inset 0 0 0 2px $color-primary;
      }
    }

    .row {
      display: flex;
    }

    .innerTooltip {
      margin-left: spacing(2);
    }

    &.increasedSpecificity {
      thead {
        tr {
          th {
            padding: unset;
            padding-right: spacing(8);
            border: transparent;
            vertical-align: top;
            height: 60px;
            min-height: 60px;
            &:first-child {
              min-width: 78px;
              max-width: 78px;
              width: 78px;
              white-space: pre-wrap;
              padding-right: spacing(1);
            }
          }
        }
      }

      tbody {
        tr {
          td {
            padding: unset;
            padding-right: spacing(8);
            border: transparent;
            height: 32px;
            max-height: 32px;

            &:first-child {
              padding-right: spacing(1);
              width: 78px;
            }
            &:nth-last-child(2),
            &:last-child {
              padding: spacing(1);
            }
          }
        }
      }
    }

    .fitContent {
      width: fit-content;
    }
  }
}

.titleRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: spacing(4);

  .fileImportButton {
    margin-left: auto;
  }
}

.header {
  color: $color-text-dark;
}

.fileName {
  font-weight: $font-weight-bold;
}

.createdAt {
  color: $color-input-main;
  font-size: $font-size-14;
}

.wrappedFileName,
.wrappedHeader {
  white-space: break-spaces;
}

.wrappedHeader {
  height: spacing(16);
  vertical-align: top;
}
