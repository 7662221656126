@import 'styles/variables';

.header {
  width: 100%;
  background: $color-white;
  height: $header-height;
  box-shadow: 0 0 2px $color-shadow-main, 0 2px 2px $color-checkbox-focus, 0 1px 3px $color-shadow-secondary;
  padding: spacing(6);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  z-index: 3;

  img {
    margin-left: spacing(4);
    height: 52px;
  }

  .rightSection {
    display: flex;
    align-items: center;
  }

  .logoutButton {
    padding-right: 0;
    padding-left: 0
  }

  .separator {
    width: 2px;
    height: 30px;
    background-color: $color-background;
    margin: 0 spacing(8);
  }
}
