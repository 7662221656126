@import 'styles/variables';
@import 'styles/mixins';

.userManagementHeader {
  display: grid;
  grid-template-columns: max-content auto max-content max-content;
  align-items: center;
  justify-items: start;
  grid-gap: spacing(4);
  margin-bottom: spacing(7);
}
.invisible {
  visibility: hidden;
}
.searchInput {
  margin-bottom: spacing(2);
}
.generalUsersListInput input {
  min-width: 328px;
}
