@import 'styles/variables';
@import 'styles/mixins';

.tabsRow {
  display: flex;
  overflow-x: auto;
  // Hack to make room for box-shadow of single tab
  width: calc(100% + spacing(5));
  margin-left: spacing(-2.5);
  padding-left: spacing(2.5);
  padding-right: spacing(2.5);

  .addButton {
    padding: spacing(4);
    border-top: 1px solid transparent;
    white-space: nowrap;
  }

  &.primaryTabsContainer {
    margin-left: unset;
    padding-left: unset;
    width: 100%;
    border-bottom: 4px solid $color-primary;
  }
}