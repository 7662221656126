@import 'styles/variables';

.container {
  padding: spacing(6) spacing(4);
  margin-top: spacing(6);
}

.logsHeader {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  grid-gap: spacing(4);
}

.inputSection {
  padding-top: spacing(2);
}

.logsContainer {
  display: flex;
  padding-top: spacing(6);
  flex-wrap: wrap;
  & > * {
    margin: 10px;
  }
}

.filters {
  color: $color-primary;
}

.searchInput {
  display: flex;
  margin-top: spacing(2);
}

.dateInput {
  display: flex;
  justify-content: flex-end;
}

.searchInnerContainer {
  input {
    min-width: 591px;
  }
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
}

.filterButton {
  margin-left: spacing(2);
}

.downloadButton {
  padding-top: spacing(8);
}

.dateTabsRow {
  display: flex;
  align-items: center;
}

.dateTabs {
  background-color: $color-text-light;

  span {
    color: $color-text;
  }
}

.noResults {
  text-align: center;
}
.hidden {
  display: none;
}
