@import 'styles/variables';
@import 'styles/mixins';

.titleRow {
  display: grid;
  grid-template-columns: max-content;
  align-items: center;
  grid-gap: spacing(2);
  margin-bottom: spacing(6);
}
.title {
  margin-bottom: spacing(2);
}

.dateText {
  @include bodyTextSmallStyle;
  color: $color-input-main;
  &:first-of-type {
    margin-top: spacing(2);
  }
}
