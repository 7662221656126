@import 'styles/variables';
@import 'styles/mixins';

.container {
  .tableContainer {
    box-shadow: unset;
    border: unset;

    .iconButton {
      display: flex;

      &:focus {
        box-shadow: inset 0 0 0 2px $color-error;
      }

      &.disabledIcon {
        cursor: not-allowed;
        svg path {
          fill: $color-background-disabled;
        }
      }
    }

    thead {
      tr {
        th {
          &:first-child {
            padding-left: unset;
          }
          &:last-child {
            padding-right: unset;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          &:first-child {
            padding-left: unset;
          }
          &:last-child {
            padding-right: unset;
          }
        }
      }
    }
  }

  h4 {
    margin-bottom: spacing(4);
  }
}

.filesTable {
  flex: 1;
}

.altFilesContainer {
  display: grid;
  grid-template-columns: minmax(194px, max-content) max-content max-content;
  grid-row-gap: spacing(2);
  grid-column-gap: spacing(4);
  align-items: center;
  margin-bottom: spacing(8);

  h3 {
    grid-column: 1/4;
    font-size: $font-size-16;
    margin-bottom: spacing(2);
  }

  label {
    font-size: $font-size-16;
    font-weight: $font-weight-bold;
  }

  span {
    font-size: $font-size-14;
  }

  button {
    width: 16px;
    height: 17px;
  }
}
