@import 'styles/variables';
@import 'styles/mixins';

.formContainer {
  display: flex;
  flex-direction: column;
  width: 514px;
  background: $color-white;
  padding: spacing(6) spacing(8) spacing(6) 0;
  border: 1px solid $color-border;
  border-radius: 6px;
  box-shadow: 0 4px 10px $color-shadow-main;
  margin-top: spacing(6);

  .formButtons {
    align-self: end;
    padding-top: spacing(2);
  }

  .inputInternalPosition {
    justify-content: flex-end;
    margin-bottom: spacing(4);
    padding-right: spacing(10);

    >input {
      width: 274px;
    }
    >div>input {
      width: 274px;
    }
  }
}
