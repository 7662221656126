@import 'styles/variables';
@import 'styles/mixins';

.editableContainer {
  width: 375px;
  margin-top: spacing(8);
  padding: spacing(2) spacing(4);
  grid-gap: spacing(4);
  grid-template-rows: 32px auto;

  .headerContent {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $color-border;
    padding-bottom: spacing(2);
    align-items: center;

    .title {
      color: $color-border-secondary;
      text-decoration: underline;
    }

    .editButton {
      padding: spacing(1) spacing(1.5);

      svg {
        width: 21px;
        height: 20px;
      }
    }
    span {
      @include bodyTextSmallStyle;
    }
  }
  .listItem {
    @include bodyTextSmallStyle;
  }
}
