@import 'styles/variables';

.titleRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: spacing(6);
}

.buttonsContainer {
  display: flex;
  >:not(:last-child) {
    margin-right: spacing(2);
  }
}

.separator {
  height: 1px;
  width: 100%;
  margin-top: spacing(3.75);
  margin-bottom: spacing(4);
  background-color: $color-border-secondary;
}